export enum CharClass {
    cleric = 'Cleric',
    dwarf = 'Dwarf',
    elf = 'Elf',
    fighter = 'Fighter',
    halfling = 'Halfling',
    magicUser = 'Magic-User',
    thief = 'Thief',
    normalHuman = 'Normal Human',
    // OSE Advanced
    acrobat = 'Acrobat',
    assassin = 'Assassin',
    barbarian = 'Barbarian',
    bard = 'Bard',
    drow = 'Drow',
    druid = 'Druid',
    duergar = 'Duergar',
    gnome = 'Gnome',
    half_elf = 'Half-Elf',
    half_orc = 'Half-Orc',
    illusionist = 'Illusionist',
    knight = 'Knight',
    paladin = 'Paladin',
    ranger = 'Ranger',
    svirfneblin = 'Svirfneblin',
    //Carcass Crawler #0,
    changeling = 'Changeling',
    chaos_knight = 'Chaos Knight',
    warden = 'Warden',
    // Carcass Crawler #1
    acolyte = 'Acolyte',
    gargantua = 'Gargantua',
    goblin = 'Goblin',
    hephaestan = 'Hephaestan',
    kineticist = 'Kineticist',
    mage = 'Mage',
    // Carcass Crawler #2
    phase_elf = 'Phase Elf',
    wood_elf = 'Wood Elf',
    // Carcass Crawler #3
    beast_master = 'Beast Master',
    dragonborn = 'Dragonborn',
    mutoid = 'Mutoid',
    mycelian = 'Mycelian',
    tiefling = 'Tiefling',
    // Carcass Crawler 4
    halfling_hearthsinger = 'Halfling Hearthsinger',
    halfling_reeve = 'Halfling Reeve',
    arcane_bard = 'Arcane Bard',
}
